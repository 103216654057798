import { Icons } from '@/components/icons';
import {
  defaultBooleanFilter,
  defaultDateFilter,
  defaultEnumFilter,
  defaultRelationFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { ApartmentContractConfig, TenantConfig } from '@pigello/pigello-matrix';
import baseRevenueIncreaseFilters from './base-revenue-increase-filters';

const STATUS_FILTERS: Filter['enumChoices'] = [
  {
    title: 'Aktiv',
    filters: [
      {
        operator: '__in',
        value: '0',
      },
    ],
    badgeVariant: 'green',
  },
  {
    title: 'Aktiv tillsvidare',
    filters: [
      {
        operator: '__in',
        value: '1',
      },
    ],
    badgeVariant: 'green',
  },
  {
    title: 'Aktiv till slutdag',
    filters: [
      {
        operator: '__in',
        value: '2',
      },
    ],
    badgeVariant: 'green',
  },
  {
    title: 'Uppsagt för avtalsändring',
    filters: [{ operator: '__in', value: '3' }],
    badgeVariant: 'destructive',
  },
  {
    title: 'Uppsägning pågår',
    filters: [{ operator: '__in', value: '4' }],
    badgeVariant: 'destructive',
  },
  {
    title: 'Uppsagt för förhandling',
    filters: [{ operator: '__in', value: '5' }],
    badgeVariant: 'destructive',
  },
  {
    title: 'Uppsagt',
    filters: [{ operator: '__in', value: '6' }],
    badgeVariant: 'destructive',
  },
  {
    title: 'Varning för ansvarig',
    filters: [{ operator: '__in', value: '7' }],
    badgeVariant: 'yellow',
  },
  {
    title: 'Varning för motpart',
    filters: [{ operator: '__in', value: '8' }],
    badgeVariant: 'yellow',
  },
  {
    title: 'Kommande',
    filters: [{ operator: '__in', value: '9' }],
    badgeVariant: 'blue',
  },
  {
    title: 'Ej signerat',
    filters: [{ operator: '__in', value: '10' }],
    badgeVariant: 'purple',
  },
  {
    title: 'Inaktiv, uppsägning ej signerad',
    filters: [{ operator: '__in', value: '11' }],
    badgeVariant: 'destructive',
  },
  {
    title: 'Inaktiv',
    filters: [{ operator: '__in', value: '12' }],
    badgeVariant: 'gray',
  },
];

const baseContractFilters: Filter[] = [
  {
    ...defaultEnumFilter,
    title: ApartmentContractConfig.fields.status.verboseName,
    externalFieldName: ApartmentContractConfig.fields.status.externalFieldName,
    enumChoices: STATUS_FILTERS,
  },
  {
    ...defaultRelationFilter,
    title: ApartmentContractConfig.fields.tenant.verboseName,
    externalFieldName: ApartmentContractConfig.fields.tenant.externalFieldName,
    relationModelName: TenantConfig.modelName,
    icon: Icons.tennant,
  },
  {
    ...defaultStringFilter,
    title: ApartmentContractConfig.fields.idNumber.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.idNumber.externalFieldName,
    icon: Icons.id,
  },
  {
    ...defaultStringFilter,
    title: ApartmentContractConfig.fields.customId.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.customId.externalFieldName,
    icon: Icons.id,
  },
  {
    ...defaultDateFilter,
    title: ApartmentContractConfig.fields.startDate.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.startDate.externalFieldName,
  },
  {
    ...defaultDateFilter,
    title: ApartmentContractConfig.fields.initialEndDate.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.initialEndDate.externalFieldName,
  },
  {
    ...defaultDateFilter,
    title: ApartmentContractConfig.fields.lifetimeEndDate.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.lifetimeEndDate.externalFieldName,
  },
  {
    ...defaultRelationFilter,
    title: ApartmentContractConfig.fields.biTenants.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.biTenants.externalFieldName,
    relationModelName: TenantConfig.modelName,
    icon: Icons.tennant,
  },
  {
    ...defaultDateFilter,
    title: ApartmentContractConfig.fields.cancellationTime.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.cancellationTime.externalFieldName,
  },
  {
    ...defaultDateFilter,
    title: ApartmentContractConfig.fields.invoicingPeriodFrom.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.invoicingPeriodFrom.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title: ApartmentContractConfig.fields.invoicingMarking.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.invoicingMarking.externalFieldName,
  },
  {
    ...defaultStringFilter,
    title:
      ApartmentContractConfig.fields.invoicingCustomerReference.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.invoicingCustomerReference
        .externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title: ApartmentContractConfig.fields.invoicingDebtBlocked.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.invoicingDebtBlocked.externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title: ApartmentContractConfig.fields.invoicingReminderBlocked.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.invoicingReminderBlocked.externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title: ApartmentContractConfig.fields.invoiceMergeIndexInView.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.invoiceMergeIndexInView.externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title:
      ApartmentContractConfig.fields.invoiceMergeNonBaseRentInView.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.invoiceMergeNonBaseRentInView
        .externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title:
      ApartmentContractConfig.fields.invoicingAutomaticCreationPaused
        .verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.invoicingAutomaticCreationPaused
        .externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title: ApartmentContractConfig.fields.invoicingAttemptAutogiro.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.invoicingAttemptAutogiro.externalFieldName,
  },
  {
    ...defaultDateFilter,
    title: ApartmentContractConfig.fields.signedTime.verboseName,
    externalFieldName:
      ApartmentContractConfig.fields.signedTime.externalFieldName,
  },
  ...baseRevenueIncreaseFilters,
];

export default baseContractFilters;
